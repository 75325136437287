

import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';



import React from "react"

import i18next from '../i18n/config';
// import ImgHeader from "../img/A_glowna_1/tlo_glowna.jpg";



export default function Header(props) {



    return (
            <React.Fragment>

                <section id="error" class="firstSection">

                    <h2>{i18next.t('404h2')}</h2>
                    <p>{i18next.t('404p')}</p>

                    <div class="kontener">
                        <div class="przepis">
                            <h3>{i18next.t('404h3')}</h3>
                            <p>{i18next.t('404point1')}</p>
                            <p>{i18next.t('404point2')}</p>
                            <p>{i18next.t('404point3')}</p>
                            <p>{i18next.t('404point4')}</p>
                            <p>{i18next.t('404point5')}</p>
                        </div>
                        <div class="kawa">
                            <picture>
                                <source media="(min-width: 1024px)" srcset="/img/OurCoffees/cappuccino_glass_cup.png" />
                                <img loading="lazy" src="/img/OurCoffees/cappuccino_glass_cup_@0,75x.png"
                                     alt="Instanta Coffee Cappucino Glass Cup" />
                            </picture>
                        </div>
                    </div>

                </section>


            </React.Fragment>

            )


}
