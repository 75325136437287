import React from "react"

import ReactDOM from "react-dom";
import {
Route,
        NavLink,
        HashRouter,
        BrowserRouter,
        Switch,
        useLocation
} from "react-router-dom";
//import GatsbyConfig from '../../gatsby-config'


import 'isomorphic-fetch'; //index file create

// fonts

import "fontsource-roboto/latin-ext-300.css"
import "fontsource-roboto/latin-ext-400.css"
import "fontsource-roboto/latin-ext-700.css"

// end fonts

import { Router } from "@reach/router"


// Import layouts here
import Suwak from "../layouts/Suwak"

import Menu from "../layouts/menu";
import Error404 from "../layouts/404";
import Footer from "../layouts/footer";
// Import styles here
import '../styles/global.css';
import '../styles/404.css';
// plugins
import { Helmet }   from "react-helmet";
import i18next from '../i18n/config';
//function chlang(lang) {
//    console.log(lang, "chlang");
//}


require('typeface-roboto');

// import favicon from '../img/favi_icon_16px.png';
//      <link rel="icon" type="image/png" href={favicon} sizes="16x16" />

// const $ = require("jquery")



export default  class Home extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        i18next.changeLanguage(this.props.pageContext.language, (err, t) => {
        });
//        console.log(this);
//    i18next.changeLanguage(this.props.pageContext.language, (err, t) => {
//    });


//    if (typeof document !== `undefined`) {
//        locationTmp = window.location.pathname + window.location.search;
//        let first3 = locationTmp.substr(0, 3);
//        if (first3 == "/pl") {
//            i18next.changeLanguage('pl', (err, t) => {
//            });
//        }
//        if (first3 == "/es") {
//            i18next.changeLanguage('es', (err, t) => {
//            });
//        }
//    }



        return (
                <React.Fragment>

                    <div className="App" >
                        <Helmet>
                            <meta charSet="utf-8" />
                            <link rel="canonical" href="https://www.instanta.pl/" />
                            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                            <link rel="icon" type="image/png" href={require('../img/template/favicon.png')} sizes="16x16" />
                            <title>Instanta Coffee Brand</title>
                            <meta name="Description" content="The company specializes in the production of private label coffee" />
                            <meta name="Keywords" content="private label coffee" />
                        </Helmet>
                



                        <div class="wrapper">
                            <Menu/>
                            <Error404/>
                            <Footer/>
                            <Suwak/>
                        </div>

                    </div>



                </React.Fragment>

                )
    }
}